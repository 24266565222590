import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { graphql, Link } from "gatsby";
import Layout from "../layout/index.en";
import PostListing from "../components/PostListing/PostListing";
import DownloadAPP from "../components/DownloadAPP/DownloadAPP.en";
import SEO from "../components/SEO/SEO";
import "./listing.css";

class ListingEn extends React.Component {
  renderPaging() {
    const { currentPageNum, pageCount } = this.props.pageContext;
    const prevPage = currentPageNum - 1 === 1 ? "/blog/" : `/blog/${currentPageNum - 1}/`;
    const nextPage = `/blog/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    return (
      <div className="pagingContainer">
        {!isFirstPage && <Link to={prevPage}>Previous</Link>}
        {!isLastPage && <Link to={nextPage}>Next</Link>}
      </div>
    );
  }

  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;

    return (
      <Layout>
        <div className="listing-container">
          <div className="posts-container">
            <h1>ArgoVPN Blog</h1>
            <HelmetProvider>
              <Helmet title="ArgoVPN Blog" />
            </HelmetProvider>
            <SEO />
            <PostListing postEdges={postEdges} />
          </div>
          {this.renderPaging()}
        </div>
        <DownloadAPP />
      </Layout>
    );
  }
}

export default ListingEn;

/* eslint no-undef: "off" */
export const listingENQuery = graphql`
  query listingENQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: {type: {eq: "blog"}, langKey: {eq: "en"}  } } 
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
